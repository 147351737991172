.contact-container h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 75px;
  color: #ffffff;
  text-align: center;
  margin-top: 4.875rem;
}
.contact-container h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 75px;
  color: #ffffff;
}
.contact-input-container {
  display: flex;
  justify-content: space-between;
  padding: 4.8125rem 0;
  /* margin:20px; */
}
.contact-input {
  max-width: 700px;
}
.contact-container input {
  width: 395px;
  background-color: transparent;
  border: 0;
  font-size: 20px;
  border-bottom: 2px solid #fafafa;
  margin-bottom: 24px;
  color: #fafafa;
}
.contact-container textarea {
  width: 100%;
  height: 80px;
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #fafafa;
  margin-bottom: 38px;
  color: #fafafa;
}
.contact-container textarea,
.contact-container input {
  outline: none;
  caret-color: white;
}
.contact-container label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 45px;
  color: #ffffff;
}
.first-two {
  margin-right: 5.1875rem;
}
.contact-submit {
  width: 100%;
  height: 49px;
  background: #fafafa;
  border-radius: 11px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  color: #0f0f33;
}
.contact-information {
  width: 22.3125rem;
  height: 480px;
  background: #fafafa;
  border-radius: 24px;
  padding: 94px 45px;
}
.contact-information h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 36px;
  color: #0f0f33;
}
.contact-information p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  color: #0f0f33;
}
.contact-information-icon {
  width: 25px;
  height: 25px;
  margin-right: 18px;
}
.follow-us {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 45px;
  text-align: center;
  margin-top: 73px;
  margin-bottom: 37px;
  color: #0f0f33;
}
.let-connect {
  width: 90%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff !important;
  background: #0072b1;
  border-radius: 11px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
  margin: 0 auto;
}
.slider-container {
  max-width: 66.875rem;
  margin: 0 auto;
  margin-bottom: 8.1875rem;
}
.slider-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 75px;
  color: #ffffff;
}
.slide-img{
  width: 100%;
  height: 600px;
  overflow: hidden;
}
.carousel-control-next {
  right: -7.5rem !important;
}
.carousel-control-prev {
  left: -7.5rem !important;
}

.error-text {
  color: red;
}
.success-text {
  color: green;
}
/* responsive */



@media only screen and (max-width: 1200px) {
  .first-two {
    margin-right: 0;
  }
  .slide-img{
    height: 50vw;
  }
}
@media only screen and (min-width: 600px) and (max-width: 992px) {
  /* carousel */
  .slider-container {
    max-width: 50.875rem;
  
  }
  .carousel-control-next {
    right: -4.5rem !important;
  }
  .carousel-control-prev {
    left: -4.5rem !important;
  }
  .contact-input-container {
    flex-direction: column;
  }
  .contact-container input {
    width: 100%;
  }
  .contact-information {
    width: 70%;
    margin: 0 auto;
    margin-top: 20px;
  }
  .first-two {
    margin-right: 0;
  }
  .slider-title {
    padding: 4.8125rem 5.0625rem;
  }
}
@media only screen and (max-width: 600px) {

 
  
  .contact-container h1 {
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
  }
  .contact-container label {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
  }
  .contact-submit{
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
  }
 
  .contact-input-container {
    flex-direction: column;
  }
  .first-two {
    margin-right: 0;
  }
  .contact-container input {
    width: 100%;
 
  }
  .contact-information {
    width: 100%;
  }
  .slider-container {
    max-width: 90%;
  }
  .contact-submit {
    margin-bottom: 3rem;
  }
  .slider-title {
    padding: 4.8125rem 5.0625rem;
  }
  .carousel-control-next {
    right: -4.5rem !important;
  }
  .carousel-control-prev {
    left: -4.5rem !important;
  }
}
