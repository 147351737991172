.product-container h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 3.125rem;
    line-height: 4.6875rem;
    margin-top: 3rem;
    text-align: center;
    color: #ffffff;
    margin-bottom: 3rem;
}
.chart-container {
    width: 414px;
    height: 414px;
    margin: 0 auto;
    padding: 30px;
}
#center-text{
    left: 44%;
    top : -53%;
    position: relative;
    font-size: 15px;
    font-weight: 400;
}
#word-center{
    position:absolute;
}
.circle-text {
    width: 18.25rem;
    height: 3.75rem;
    border: 3px solid #fafafa;
    border-radius: 0.75rem;
    margin: 0 auto;
    margin-top: 3.5625rem;
    margin-bottom: 4rem;
    padding: 1rem 1rem;
    color: #ffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.875rem;
    line-height: 2.8125rem;
}
.circle-text span {
    width: 85%;
    height: 100%;
    background: #c9af52;
    border-radius: 10px;
}

.product-item-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 4.6875rem;
    justify-items: center;
}
.product-item p {
    color: #fff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 32px;
    margin-top: 29px;
}
.product-item img {
    width: 348px;
    height: auto;
}

.buy-info {
    background: #fafafa;
    padding: 3.5625rem 7.5rem 3.5rem 5.1875rem;
    display: flex;
    justify-content: space-between;
}
.buy-info input {
    width: 260px;
    height: 60px;
    border: 3px solid #0f0f33;
    border-radius: 12px;
    margin-right: 18px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding-left: 15px;
}
.product-buy-now {
    width: 283px;
    height: 60px;
    background: #0f0f33;
    border-radius: 11px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    color: #ffffff;
    margin-top: 1rem;
}

/* color wheel */
#colorWheel {
    height: 100px;
    width: 100px;
    margin: 0 auto;
    position: relative;
    -webkit-transform-origin: 50px 150px;
    -moz-transform-origin: 50px 150px;
    -ms-transform-origin: 50px 150px;
    -o-transform-origin: 50px 150px;
    transform-origin: 50px 150px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
}
#colorWheel span {
    position: absolute;
    -webkit-transform-origin: 50% 50%;
    border-style: solid;
    border-width: 150px 50px;
    box-sizing: border-box;
}

#colorWheel span.color01 {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    border-color: #43a1cd transparent transparent transparent;
}

#colorWheel span.color02 {
    -webkit-transform: rotate(36deg);
    -moz-transform: rotate(36deg);
    -ms-transform: rotate(36deg);
    -o-transform: rotate(36deg);
    transform: rotate(36deg);
    border-color: #639b47 transparent transparent transparent;
}

#colorWheel span.color03 {
    -webkit-transform: rotate(72deg);
    -moz-transform: rotate(72deg);
    -ms-transform: rotate(72deg);
    -o-transform: rotate(72deg);
    transform: rotate(72deg);
    border-color: #9ac147 transparent transparent transparent;
}

#colorWheel span.color04 {
    -webkit-transform: rotate(108deg);
    -moz-transform: rotate(108deg);
    -ms-transform: rotate(108deg);
    -o-transform: rotate(108deg);
    transform: rotate(108deg);
    border-color: #e1e23b transparent transparent transparent;
}

#colorWheel span.color05 {
    -webkit-transform: rotate(144deg);
    -moz-transform: rotate(144deg);
    -ms-transform: rotate(144deg);
    -o-transform: rotate(144deg);
    transform: rotate(144deg);
    border-color: #f7941e transparent transparent transparent;
}

#colorWheel span.color06 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    border-color: #ba3e2e transparent transparent transparent;
}

#colorWheel span.color07 {
    -webkit-transform: rotate(216deg);
    -moz-transform: rotate(216deg);
    -ms-transform: rotate(216deg);
    -o-transform: rotate(216deg);
    transform: rotate(216deg);
    border-color: #9a1d34 transparent transparent transparent;
}
#colorWheel span.color08 {
    -webkit-transform: rotate(252deg);
    -moz-transform: rotate(252deg);
    -ms-transform: rotate(252deg);
    -o-transform: rotate(252deg);
    transform: rotate(252deg);
    border-color: #662a6c transparent transparent transparent;
}

#colorWheel span.color09 {
    -webkit-transform: rotate(288deg);
    -moz-transform: rotate(288deg);
    -ms-transform: rotate(288deg);
    -o-transform: rotate(288deg);
    transform: rotate(288deg);
    border-color: #272b66 transparent transparent transparent;
}

#colorWheel span.color10 {
    -webkit-transform: rotate(324deg);
    -moz-transform: rotate(324deg);
    -ms-transform: rotate(324deg);
    -o-transform: rotate(324deg);
    transform: rotate(324deg);
    border-color: #2d559f transparent transparent transparent;
}
#colorWheel:before {
    content: "";
    width: 300px;
    height: 300px;
    overflow: hidden;
    position: absolute;
    top: -30px;
    left: -130px;
    border-radius: 100%;
    border: 30px solid #ffffff;
    z-index: 100;
}

#colorWheel:after {
    content: "";
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: absolute;
    top: 100px;
    left: 0px;
    border-radius: 100%;
    background: #ffffff;
}

/* responsive */
@media only screen and (min-width: 768px) and (max-width: 1000px) {
    .product-item-container {
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
        align-items: center;
    }
    .buy-info {
        flex-direction: column;
    }
    .buy-info input,
    .product-buy-now {
        width: 99%;
        margin-bottom: 2rem;
    }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
    .product-item-container {
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        align-items: center;
    }
    .buy-info {
        flex-direction: column;
    }
    .buy-info input,
    .product-buy-now {
        width: 99%;
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width: 600px) {
    .product-container h1 {
        font-size: 30px;
        line-height: 45px;
    }
    .circle-text {
        width: 172px;
        height: 40px;
        font-size: 20px;
        line-height: 35px;
        border-radius: 10px;
        /* width: fit-content;
    padding: 15px 20px; */
    }
    .circle-text span {
        width: 85%;
        height: 100%;
    }
    .product-item-container {
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        align-items: center;
    }
    .buy-info {
        flex-direction: column;
    }
    .buy-info input,
    .product-buy-now {
        width: 100%;
        margin-bottom: 2rem;
        font-size: 20px;
    }

    .chart-container {
        width: 80vw;
        height: 80vw;
        margin: 0 auto;
    }
    #center-text{
        font-size: 12px;
        left:40%;
    }
}
