.our-facilities-container h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 75px;
  color: #ffffff;
  text-align: center;
  margin-top: 4.8125rem;
}
.show-carousel{
  display: none;
}
.our-facilities-chip-container {
  display: flex;
  justify-content: space-evenly;
  padding: 6.25rem 0px;
  color: #ffff;
  
}

.our-facilities-chip,.our-facilities-chip-production {
  width: 259px;
  height: 90px;
  display: flex;
  border: 4px solid #f5f5f5;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  color: #fafafa;
  margin-bottom: 30px;
  cursor: pointer;
  text-align: center;
}

.our-facilities-chip-production{
  width: 350px;
}


.our-facilities-chip-selected,.our-facilities-chip-selected-production{
  width: 259px;
  height: 90px;
  display: flex;
  background-image: url("../assets/images/chip-bg.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 4px solid #f5f5f5;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: black;
  margin-bottom: 30px;
  cursor: pointer;
  text-align: center;
}

.our-facilities-chip-selected-production{
  width: 350px;
}

.our-facilities-gallery-container{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 10px;
}
.our-facilities-img-container{
width: 100%;
height: auto;
overflow: hidden;

}
.our-facilities-img-container img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.our-facilities-container .rectangle-container {
  display: flex;
  justify-content: space-between;
}
.our-facilities-container .rectangle {
  width: 362px;
  /* height: 414px; */
  color: black;
  background: #fafafa;
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 1.875rem;
}
.our-facilities-container .rectangle-image {
  margin: 0 auto;
  width: 100%;
  display: flex;
}
.our-facilities-container .rectangle-image img {
  margin: 0 auto;
}

.our-facilities-container .rectangle p {
  color: black;
  text-align: center;
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 3rem;
}
.rectangle-container p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #f5f5f5;
  margin-left: 2.25rem;
}
.instrument-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #f5f5f5;
  margin-bottom: 9rem;
}
.instrument-img {
  width: 100%;
  margin-top: 6.4375rem;
  margin-bottom: 5.125rem;
}
.instrument-img img {
  width: 100%;
  height: auto;
}

/* responsive */
@media only screen  and (min-width: 1440px) {
  .our-facilities-img-container{
    width: 100%;
    height: 300px;
    overflow: hidden;
    }
}

@media only screen and (min-width: 600px) and (max-width: 992px) {
  /* .our-facilities-gallery-container */
  .our-facilities-gallery-container{
    grid-template-columns: repeat(2,1fr);
  }
  .our-facilities-chip-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
  
  .rectangle-container {
    padding: 1rem;
  }
  .instrument-text {
    padding: 1rem;
  }
  .our-facilities-container .rectangle-container {
 flex-direction: column;
 align-items: center;
  }
  .our-facilities-container  .rectangle{
    width: 100%;
  }
 
}

@media only screen and (max-width: 600px) {
    /* .our-facilities-gallery-container */
    .our-facilities-gallery-container{
      grid-template-columns: repeat(1,1fr);
    }
    .our-facilities-img-container{
    
      height: fit-content;
    
      }
  .our-facilities-container h1 {
    font-size: 30px;
    line-height: 45px;
  }
  .our-facilities-chip,.our-facilities-chip-selected{
    font-size: 20px;
    line-height: 35px;
  }
  .our-facilities-chip-container {
    flex-direction: column;
    align-items: center;
  }
  .our-facilities-container  .rectangle{
    width: 100%;
  }
  .our-facilities-container .rectangle p,.rectangle-container p,.instrument-text{
    font-size: 20px;
    line-height: 38px;
  }
  .rectangle-container {
    flex-direction: column;
  }
  .rectangle-container p {
    margin: 0;
    padding: 0 20px;
  }
  .instrument-text {
    padding: 0 20px;
  }
  .our-facilities-chip {
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .our-facilities-chip-selected {
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .our-facilities-chip-container{
    display: none;
  }
  .show-carousel{
    display: block;
  }
}
