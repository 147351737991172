@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  background-color: #0f0f33 !important;
  font-family: "Poppins";
  overflow-x: hidden;
}
@media only screen and (max-width: 600px) {
  html {
    font-size: 70%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 992px) {
  html {
    font-size: 80%;
  }
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
  html {
    font-size: 30%;
  }
}

/* layout */

.main-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 75px;
}
.sub-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 45px;
}
.container-custom {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 !important;
}

/* navbar */
.bread-btn {
  color: white !important;
  height: 40px;
  width: 40px;
}
.bar {
  height: 40px;
  width: 40px;
}
.navbar-container {
  border-bottom: 2px solid #f5f5f5;
  padding: 0 !important;
}
.nav-item {
  color: #ffffff !important;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 17px !important;
  line-height: 30.5px !important;
  margin-left: 29px;
}
.nav-brand {
  color: #ffffff !important;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 2.3125rem !important;
  line-height: 3.4688rem !important;
  height: 70px;
  /* padding: 0 !important;
  padding-bottom: 10px !important; */
}
.nav-brand img {
  /* box-shadow: 1px 1px 4px -1px  #ffffff96; */
  /* filter: drop-shadow(4px 4px 2px #ffffff96); */
}
.navbar-toggler {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* banner */
.banner-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.banner-content {
  width: 55%;
  color: #ffffff;
  padding: 2rem;
}
.banner-content h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2.8125rem;
  line-height: 4.25rem;
}

.banner-content p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 2.625rem;

  color: #f5f5f5;
}
.banner-img {
  width: 50%;
  color: #ffffff;
  padding: 2rem 0;
}
.banner-img img {
  height: auto;
  width: 100%;
}

/* our global presence */
.our-global-presence-container h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.125rem;
  line-height: 4.6875rem;
  color: #ffffff;
  text-align: center;
}
.our-global-presence-container  h4{
  color: #ffffff;
  text-align: center;
  font-size: 65px;
  font-weight: 800;
}
.country-rating-container {
  display: flex;
  justify-content: space-between;
  padding: 3rem 3rem;
}
.country-rating-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 2.8125rem;
  color: #fafafa;
}
.country-rating-number {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 2.1875rem;
  line-height: 3.25rem;

  color: #fafafa;
}
.country-description {
  width: 99%;
  margin: 0 auto;
  padding-bottom: 169px;
  height: auto;
}
.country-description h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 2.1875rem;
  line-height: 3.25rem;
  color: #f5f5f5;
}
.country-description p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 2.8125rem;

  color: #f5f5f5;
}
.half-circle {
  width: 90rem;
  height: 45rem;
  border-top-left-radius: 45rem;
  border-top-right-radius: 45rem;
  border: 7px solid #fafafa;
  border-bottom: 0;
  /* position: relative; */
  /* margin: 0 auto; */
  background-image: url('../assets/images/Reactangle19.9f404bcf8b6b.png');
  background-size:cover;
  background-position: 500px;
}
.half-circle-icon-one {
  color: #ffffff;
  position: relative;
  /* transform: translate(504px, 273.6px); */
   transform: translate(580px, 250px);
  width: 20px;
  cursor: pointer;
}


.half-circle-icon-two {
  color: #ffffff;
  /* position: absolute; */
  position: relative;
  /* transform: translate(288px, 360px); */
  transform: translate(595px, 188px);
  width: 20px;
  cursor: pointer;
}
.half-circle-icon-three {
  color: #ffffff;
  /* position: absolute; */
  position: relative;
  /* transform: translate(1080px, 288px); */
  transform: translate(615px, 210px);
  width: 20px;
  cursor: pointer;
}
.half-circle-icon-four {
  color: #ffffff;
  /* position: absolute; */
  position: relative;
  /* transform: translate(720px, 432px); */
  transform: translate(710px, 215px);
  width: 20px;
  cursor: pointer;
}
.half-circle-icon-five{
  color: #ffffff;
  /* position: absolute; */
  position: relative;
  /* transform: translate(520px, 442px); */
  transform: translate(560px, 120px);
  width: 20px;
  cursor: pointer;
}
.half-circle-icon-six {
  color: #ffffff;
  /* position: absolute; */
  position: relative;
  /* transform: translate(820px, 232px); */
  transform: translate(180px, 180px);
  width: 20px;
  cursor: pointer;
}
.half-circle-icon-seven {
  color: #ffffff;
  /* position: absolute; */
  position: relative;
  /* transform: translate(1120px, 432px); */
  transform: translate(245px, 350px);
  width: 20px;
  cursor: pointer;
}
.half-circle-icon-eight {
  color: #ffffff;
  /* position: absolute; */
  position: relative;
  /* transform: translate(454px, 220px); */
  transform: translate(955px, 220px);
  width: 20px;
  cursor: pointer;
}
.half-circle-icon-nine {
  color: #ffffff;
  /* position: absolute; */
  position: relative;
  /* transform: translate(620px, 32px); */
  transform: translate(960px, 110px); 

  width: 20px;
  cursor: pointer;
}
.half-circle-icon-ten {
  color: #ffffff;
  /* position: absolute; */
  position: relative;
  /* transform: translate(220px, 432px); */
  transform: translate(970px, 228px);
  width: 20px;
  cursor: pointer;
}
.half-circle-icon-eleven {
  color: #ffffff;
  /* position: absolute; */
  position: relative;
  /* transform: translate(150px, 300px); */
  transform: translate(654px, 45px);
  width: 20px;
  cursor: pointer;
}

.half-circle-icon-line-none {
  display: none;
}
.half-circle-icon-line {
  position: absolute;
  bottom: 20px;
  left: 4px;
  height: calc(16vw);
  width: 3px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  /* transform: rotate(90deg); */
  cursor: pointer;
}
.half-circle-icon-content{
  position: relative;
  margin-left: 1.25rem;
}
.half-circle-icon-content h5{
  font-size: 2.5rem;
}
.half-circle-icon-line-two {
  position: absolute;
  bottom: 20px;
  left: 4px;
  height: calc(5vw);
  width: 3px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  /* transform: rotate(90deg); */
  cursor: pointer;
}
.half-circle-icon-line-three {
  position: absolute;
  bottom: 20px;
  left: 4px;
  height: calc(32vw);
  width: 3px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  /* transform: rotate(90deg); */
  cursor: pointer;
}
.half-circle-icon-line-four {
  position: absolute;
  bottom: 20px;
  left: 4px;
  height: calc(32vw);
  width: 3px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  /* transform: rotate(90deg); */
}
.half-circle-icon-line-five {
  position: absolute;
  bottom: 20px;
  left: 4px;
  height: calc(32vw);
  width: 3px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  /* transform: rotate(90deg); */
}
.half-circle-icon-line-six {
  position: absolute;
  bottom: 20px;
  left: 4px;
  height: calc(32vw);
  width: 3px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  /* transform: rotate(90deg); */
}
.half-circle-icon-line-seven {
  position: absolute;
  bottom: 20px;
  left: 4px;
  height: calc(32vw);
  width: 3px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  /* transform: rotate(90deg); */
}
.half-circle-icon-line-eight {
  position: absolute;
  bottom: 20px;
  left: 4px;
  height: calc(32vw);
  width: 3px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  /* transform: rotate(90deg); */
}
.half-circle-icon-line-nine {
  position: absolute;
  bottom: 20px;
  left: 4px;
  height: calc(32vw);
  width: 3px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  /* transform: rotate(90deg); */
}
.half-circle-icon-line-ten {
  position: absolute;
  bottom: 20px;
  left: 4px;
  height: calc(32vw);
  width: 3px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  /* transform: rotate(90deg); */
}
.half-circle-icon-line-eleven {
  position: absolute;
  bottom: 20px;
  left: 4px;
  height: calc(32vw);
  width: 3px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  /* transform: rotate(90deg); */
}
/* products-container */
.home-products-container {
  background-color: #fafafa;
  display: flex;

}
.home-products-container .products-image-container {
  width: 45%;
  display: flex;
  align-items: center;
}
.home-products-container .products-image-container img {
  width: 100%;
  height: auto;
}
.home-products-container .product-content {
  width: 55%;
  padding: 3.125rem 3.3125rem 5rem 3.3125rem;
}
.home-products-container .product-content h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.125rem;
  line-height: 4.6875rem;
  color: #0f0f33;
  text-align: center;
}
.home-products-container .product-content p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.475rem;
  line-height: 3.25rem;
  color: #0f0f33;
}
.home-products-container .product-content button {
  width: 254px;
  height: 49px;
  color: #ffffff;
  background: #0f0f33;
  border-radius: 0.6875rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 2.8125rem;
  color: #ffffff;
}
/* our motto */
.our-motto-container {
  color: #ffffff;
}
.our-motto-container h1 {
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.4375rem;
  line-height: 5.125rem;
  color: #ffffff;
}
.our-motto-container p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size:1.3rem;
  line-height: 2.5rem;
  margin-top:40px;

  color: #f5f5f5;
}

/* our service */

.home-our-service-img{
height: 150px;
width: 250px;
overflow: hidden;
margin: 0 auto;
}

.home-our-service-img img{
  width: 100%;
  height: auto;
}


.our-service-container {
  padding: 3.875rem 6.25rem 3rem 1.8125rem;
}
.our-service-container h1,
.our-service-container p {
  color: #ffffff;
  text-align: center;
}
.our-service-container p{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 2.1875rem !important;
  line-height: 3.25rem;
text-align: left;
  color: #f5f5f5;
  margin-top: 40px;
  margin-bottom: 40px;
}
.our-service-container h1 {
  /* margin-bottom: 4.875rem; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 3.4375rem;
  line-height: 5.125rem;

  color: #ffffff;
}
.our-service-container p{
  font-size: 20px !important;
}
.our-service-container h3 {
  color: #ffffff;
}
.rectangle-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4.5625rem;
}

.our-service-container .rectangle-selected {
  width: 150px;
  height: 150px;
  color: #0f0f33;
  background: #fafafa;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 32px;
}
.rectangle-icon {
  width: 65px;
  height: 65px;
}
.our-service-container .rectangle {
  width: 150px;
  height: 150px;
  color: #ffffff;
  border: 3px solid #f5f5f5;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 32px;
}
.our-service-container .rectangle-container p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  color: #ffffff;
  text-align: center;
}
.rectangle-text {
  margin: 0 !important;
  font-size: 20px;
}
.rectangle-two {
  width: 265px;
  height: 364px;
  padding: 31px 42px 26px 40px;
  background: linear-gradient(
      0deg,
      rgba(64, 18, 108, 0.2),
      rgba(64, 18, 108, 0.2)
    ),
    url("../assets/images/about.png");
  background-position: center;
  background-size: cover;
  border: 3px solid #f5f5f5;
  border-radius: 18px;
  transition: background 0.5s ease-in-out;
}
.rectangle-two:hover {
  background: linear-gradient(
      0deg,
      rgba(64, 18, 108, 0.2),
      rgba(64, 18, 108, 0.2)
    ),
    url("../assets/images/rect.gif");
}

.rectangle-two h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 3.5625rem;
  color: #f5f5f5;
}
.rectangle-two p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
  text-align: center;

  color: #f5f5f5;
}
.service-title {
  margin-bottom: 75px;
}
.service-title h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2.8125rem;
  line-height: 4.25rem;
  color: #ffffff;
}
.service-title p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 2.1875rem;
  line-height: 3.25rem;

  color: #f5f5f5;
  text-align: left;
}
.rectangle-two-container {
  display: flex;
  justify-content: space-between;
}
.rectangle-two-container h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  /* identical to box height */

  color: #ffffff;
}
.rectangle-two-container p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  text-align: center;

  color: #f5f5f5;
}

/* footer-container */
.footer-container {
  color: #ffffff;
  border-top: 2px solid #f5f5f5;
  padding: 3rem 0;
  margin: 3rem auto;
}
footer h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 52px;
  color: #ffffff;
}
footer p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
  color: #fafafa;
  margin-bottom: 4.125rem;

}
.footer-link{
  font-family: "Poppins";
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
  color: #fafafa;
  margin-bottom: 4.125rem;
  text-decoration: none;
}
footer label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 45px;
  color: #ffffff;
}
footer input:focus,
footer textarea:focus {
  outline: none;
  caret-color: white;
}
footer input,
footer textarea {
  border: 0;
  border-bottom: 2px solid #fafafa;
  background: transparent;
  width: 20.6875rem;
  margin-bottom: 30px;
  color: #fafafa;
  font-size:20px;
}
.lets-connect {
  background-color: #0072b1;
  width: 237px;
  height: 60px;
  border-radius: 11px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 45px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-submit {
  width: 18.6875rem;
  height: 49px;
  background: #fafafa;
  border-radius: 11px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  color: #0f0f33;
}

/* responsive */
@media only screen and (min-width: 1440px) {
  .half-circle-icon-line{
    height: 350px;
  }
  .half-circle-icon-line-two ,.half-circle-icon-line-three {
    height:360px;
  }
  .half-circle-icon-line-four{
    height: 300px;
  }
  .half-circle-icon-line-five{
    height: 300px;
  }
  .half-circle-icon-line-six{
    height: 300px;
  }
  .half-circle-icon-line-seven{
    height: 300px;
  }
  .half-circle-icon-line-eight{
    height: 300px;
  }
  .half-circle-icon-line-nine{
    height: 300px;
  }
  .half-circle-icon-line-ten{
    height: 300px;
  }
  .half-circle-icon-line-eleven{
    height: 300px;
  }
}

@media only screen and (min-width: 1px) and (max-width: 1440px) {
  .half-circle {
    width: 98vw;
    height: 49vw;
    border-top-left-radius: 49vw;
    border-top-right-radius: 49vw;
    border: 0.4375rem solid #fafafa;
    border-bottom: 0;
    margin: 0 auto;
  }
  .half-circle-icon-line {
    height: 350px;
    width: 0.5px;
  }
  .half-circle-icon-line-two {
    height: 350px;
    width: 0.5px;
  }
  .half-circle-icon-line-three {
    height: 350px;
    width: 0.5px;
  }
  .half-circle-icon-line-four {
    height: 350px;
    width: 0.5px;
  }
  .half-circle-icon-line-five{
    height: 350px;
  }
  .half-circle-icon-line-six {
    height: 350px;
  }
  .half-circle-icon-line-seven{
    height: 350px;
  }

.half-circle-icon-line-eight{
  height: 350px;
}
.half-circle-icon-line-nine{
  height: 350px;
}
.half-circle-icon-line-ten{
  height: 350px;
}
.half-circle-icon-line-eleven{
  height: 350px;
}
  .half-circle-icon-one {
    /* transform: translate(35vw, 19vw); */
    transform: translate(44.5vw, 17vw);

  }
  .half-circle-icon-two {
 
    transform: translate(45.1vw, 12.5vw);

  }
  .half-circle-icon-three {

    transform: translate(47vw, 14vw);

  }
  .half-circle-icon-four {
    transform: translate(52.5vw, 13.5vw);
  }

  .half-circle-icon-five{
    transform: translate(43vw, 7vw);

  }
  .half-circle-icon-six {
    /* transform: translate(30vw, 20vw); */
    transform: translate(17vw, 10vw);
  }
  .half-circle-icon-seven {
  
    /* transform: translate(200vw, 70vw); */
    transform: translate(22vw,23vw) !important;
 
  }
  .half-circle-icon-eight {

    /* transform: translate(25vw, 30vw); */
    transform: translate(70vw, 13vw) !important;

  }
  .half-circle-icon-nine {
  
    transform: translate(70vw, 6vw) !important;

  }
  .half-circle-icon-ten {
  
    /* transform: translate(14vw, 25vw); */
     transform: translate(70.5vw, 13vw) !important;

  }
  .half-circle-icon-eleven {
  
    transform: translate(49.5vw, 0.7vw) !important;

  }
}
@media only screen and (min-width: 600px) and (max-width: 1440px){
  /* .half-circle{
    background-position: 50px;
  } */

  .half-circle-icon-eight {
    transform: translate(22.5vw, 18.5vw) !important;
  }
  .half-circle-icon-nine {
    transform: translate(20vw, 13vw) !important;
  }
  .half-circle-icon-seven {
    transform: translate(66vw, 27vw) !important;
  }
  .half-circle-icon-ten {
    transform: translate(22.6vw, 17vw) !important;
  }
  .half-circle-icon-eleven {
    transform: translate(10vw, 10vw);
  }

}
@media only screen and (min-width: 992px) and (max-width: 1400px) {
  .container-custom {
    padding: 15px !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 992px) {

  /* our service */
  .home-our-service-img{
    height: 150px;
    width:98%;
 
    }
  .container-custom {
    padding: 10px !important;
  }
  /* our service */
  .our-service-container {
    padding: 2rem;
  }
  .rectangle-two-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5rem;
    align-self: center;
    justify-items: center;
  }
  .country-rating-container {
    padding: 2rem;
  }
  .product-content {
    padding: 0 2.125rem;
  }

  .lets-connect {
    width: 190px;
    height: 60px;
  }
  /* product */
  .home-products-container .products-image-container {
    width: 100%;
  }
  .home-products-container {
    flex-direction: column;
  }
  .home-products-container .product-content {
    width: 100%;
   
  }
  
  .products-image-container {
    width: 100%;
  }
  /* footer */
  footer input,
  footer textarea {
    width: 97%;
  }
  .footer-submit {
    width: 97%;
  }
  .lets-connect {
    width: 97%;
  }
}

@media only screen and (max-width: 600px) {
  /* our social service */
  .our-service-container p{
    font-size: 15px !important;
    font-weight: 500;
    line-height:30px;
  }
  .container-custom {
    padding: 10px !important;
  }
  /* banner */
  .banner-content h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .banner-content p {
    font-size: 20px;
    line-height: 22px;

    color: #f5f5f5;
  }
  .banner-container {
    flex-direction: column;
  }
  .banner-content {
    width: 100%;

    padding: 3.375rem;
  }
  .banner-img {
    width: 100%;
    color: #ffffff;
    padding: 3.375rem;
  }

  /* our motto */
  .our-motto-container h1 {
    font-size: 30px;
    line-height: 42px;
  }
  .our-motto-container p {
    font-size: 18px;
    line-height: 32px;
  }
  .our-motto-container {
    padding: 4.1875rem;
  }
  /* product */
  .home-products-container {
    flex-direction: column;
  }
  .product-content {
    width: 100%;
  }
  .home-products-container .products-image-container {
    width: 100%;
  }
  .home-products-container .product-content{
    width: 100%;
  }
  .home-products-container .product-content h1 {
    font-size: 30px;
    line-height: 43px;
    text-align: left;
  }
  .home-products-container .product-content p {
    font-size: 20px;
    line-height: 44px;
  }
  .home-products-container .product-content button {
    width: 50%;
    height: 41px;

    border-radius: 11px;

    font-size: 20px;
    line-height: 35px;
  }
  .products-image-container {
    width: 100%;
  }

  /* footer */
  footer h5 {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin-top:10px;
  }
  footer label {
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
  }
  .footer-link {
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;

  }
  .footer p{
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;

  }
 
  .footer-submit {
    font-size: 20px;
    line-height: 35px;
    width: 100%;
  }

  footer input,
  footer textarea {
    width: 100%;
  }
  .lets-connect {
    width: 22.8625rem;
    font-size: 20px;
    width: 260px;
    height: 45px;
  }

  /* our service */
  .our-service-container h1 {
    font-size: 35px;
    line-height: 42px;
  }
  .our-service-container .rectangle-container p {
    font-size: 20px;
 
  }
  .rectangle-container {
    flex-direction: column;
  }
  .rectangle-two-container {
    flex-direction: column;
  }
  .rectangle-selected,
  .rectangle {
    margin: 0 auto;
  }
 
  .rectangle-two {
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .service-title h3 {
    font-size: 30px;
    line-height: 38px;
  }
  .service-title p {
    font-size: 20px;
    line-height: 32px;
  }
  .rectangle-two-container h1 {
    font-size: 30px !important;
    line-height: 38px;
  }
  .rectangle-two-container p {
    font-size: 20px;
    line-height: 28px;
  }
  /* our global presence */
  .our-global-presence-container h1 {
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
  }
  .country-rating-container {
    /* flex-direction: column; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .country-rating-text {
    font-size: 20px;
    line-height: 35px;
  }
  .country-rating-number {
    font-size: 25px;
    line-height: 42px;
  }
  .country-description h5 {
    font-size: 28px;
    line-height: 32px;
  }
  .country-description p {
    font-size: 18px;
    line-height: 35px;
  }
}
