.about-us-banner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
}
.about-us-banner-text {
    width: 60%;
}
.about-us-banner-text h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 2.125rem;
    line-height: 4.6875rem;
    color: #ffffff;
}

.contact-us {
    width: 200px;
    height: 50px;
    background: #fafafa;
    border-radius: 11px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 1.275rem;
    line-height: 2.8125rem;
    margin-bottom:20px;

    color: #0f0f33;
}
.about-us-banner-text p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    line-height: 2.2125rem;
    text-align: justify;
    color: #f5f5f5;
    margin-top:25px;
    margin: 25px;
}
.about-us-banner-img-container {
    width: 50%;
    height: 100%;
}
.about-us-banner-img-container img {
    width: 100%;
    height: auto;
}

.infographics h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 3.125rem;
    line-height: 4.6875rem;
    color: #ffffff;
}
.infographics p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.275rem;
    line-height: 2.8125rem;

    color: #f5f5f5;
}
.progress {
    height: 31px;
    border-radius: 16px;
}
.bg-info {
    background: rgba(250, 250, 250, 0.4);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: end;
}
.infographics-progress-bar-cont {
    margin-top: 54px;
}
.infographics-progress-bar-one {
    width: 100%;
    height: 31px;
    /* background: rgba(250, 250, 250, 0.4);
  border-radius: 16px;
  display: flex;
  justify-content: end;
  align-items: center; */
    margin-left: 0.625rem;
}
.infographics-progress-bar-two {
    width: 100%;
    height: 31px;
    /* background: rgba(250, 250, 250, 0.4);
  border-radius: 16px;
  display: flex;
  justify-content: end;
  align-items: center; */
    margin-left: 0.625rem;
}
.infographics-progress-bar-three {
    width: 100%;
    height: 31px;
    /* background: rgba(250, 250, 250, 0.4);
  border-radius: 16px;
  display: flex;
  justify-content: end;
  align-items: center; */
    margin-left: 0.625rem;
}
.infographics-progress-bar-four {
    width: 100%;
    height: 31px;
    /* background: #fafafa;

  border-radius: 16px;
  display: flex;
  justify-content: end;
  align-items: center; */
    margin-left: 0.625rem;
}
.infographics-progress-bar-one p,
.infographics-progress-bar-two p,
.infographics-progress-bar-three p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin: 0 10px 0 0;

    color: #fafafa;
}
.infographics-progress-bar-four p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin: 0 10px 0 0;
    color: black;
}
.infographics-rectangle-cont {
    max-width: 818px;
    display: flex;
    justify-content: space-between;
    margin: 5.625rem auto;
}
.infographics-rectangle-cont-two {
    height: 214px;
    width: 240px;
    text-align: center;
}
.infographics-rectangle {
    width: 128px;
    height: 128px;
    background: #fafafa;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.infographics-icon {
    width: 65px;
    height: 65px;
}

/* Corporate Social Responsibility */
.corporate-social-responsibility {
    background:
    linear-gradient(
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.9)
    ), url("../assets/images/csr/photo-1488521787991-ed7bbaae773c.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 5.4375rem 8rem 8.1875rem 8.5625rem;
}
.corporate-social-responsibility h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 2.5375rem;
    line-height: 4.375rem;
    text-align: center;
    margin-bottom: 34px;
    color: #ffffff;
}
.csr-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.9375rem;
    line-height: 4.375rem;
    color: #ffffff;
}
.corporate-social-responsibility p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 1.3875rem;
    line-height: 2.85rem;
    color: #f5f5f5;
}
.csr-card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 65px;
    justify-items: center;
}
.csr-card {
    width: 266px;
    height: 321px;
    border: 5px solid #fafafa;
    border-radius: 29px;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 21px;
}
.csr-card-img {
    width: 122px;
    height: 122px;
    border-radius: 50%;
    overflow: hidden;
}
.csr-card p {
    margin: 0;
    text-align: center;
    font-size: 20px;
}

/* responsive */
@media only screen and (min-width: 992px) and (max-width: 1400px) {
    .corporate-social-responsibility {
        padding: 2rem;
    }
}

@media only screen and (min-width: 600px) and (max-width: 992px) {
    .about-us-banner-text {
        width: 100%;
        padding: 0 2rem;
    }
    .about-us-banner-container {
        flex-direction: column;
    }
    .csr-card-container {
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
    }
    .corporate-social-responsibility {
        padding: 2rem;
    }
    .infographics {
        padding: 0 20px;
    }
    .infographics-rectangle-cont {
        padding: 0 20px;
    }
}

.about-us-text{
    margin-top:20px;
    margin-bottom:20px;
    font-size:25px;
}

@media only screen and (max-width: 600px) {
    .about-us-banner-text h1 {
        font-weight: 500;
        font-size: 30px;
        line-height: 55px;
    }
    .about-us-banner-text p {
        font-size: 15px;
        line-height: 25px;
    }
    .contact-us {
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
    }
    .infographics h1 {
        font-weight: 600;
        font-size: 30px;
        line-height: 55px;
    }
    .infographics p {
        font-weight: 400;
        font-size: 15px;
        line-height: 30px;
    }
    .corporate-social-responsibility h1 {
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
    }
    .corporate-social-responsibility p {
        font-weight: 400;
        font-size: 15px;
        line-height: 30px;
    }
    .csr-text {
        font-weight: 600;
        font-size: 25px;
        line-height: 60px;
    }
    .about-us-banner-container {
        flex-direction: column;
    }
    .about-us-banner-img-container {
        width: 100%;
        margin-top: 15px;
    }
    .about-us-banner-img-container img {
        max-width: 100%;
        height: auto;
    }
    .infographics-rectangle-cont {
        flex-direction: column;
    }
    .infographics-rectangle-cont-two {
        margin: 0 auto;
    }
    .corporate-social-responsibility {
        padding: 5rem;
    }
    .infographics {
        padding: 2rem;
    }
    .about-us-banner-text {
        width: 90%;
    }
    .csr-card {
        margin: 0 auto;
    }
    .csr-card-container {
        grid-template-columns: repeat(1, 1fr);
    }
    .margin-bottom {
        margin-bottom: 1.25rem;
    }
    .about-us-text{
        font-size:17px;
        line-height:25px;
    }
}
